<template>
   <div>
       <ui-h color="black">Logout</ui-h>
       <ui-p color="black">To logout of your account click the button below</ui-p>
       <ui-button primary v-on:tapped="logoutUser" :loading="isLoading">Logout</ui-button>
   </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
export default {
    data() {
        return {
            isLoading: false
        }
    },

    methods: {
        logoutUser() {
            this.isLoading = true
            firebase.auth().signOut()
                .then(() => {
                    this.$store.commit('mutationResetUserState')
                    this.$router.push('/')
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = true
                })
        }
    }
}
</script>